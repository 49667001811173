import 'bootstrap';
import '../styles/style.scss'
import './utils/polyfills'
import './utils/detectTouch'
import './utils/responsiveHelper'
import ResizeHandler from './utils/resize-active'

import 'intersection-observer' // Polyfill IntersectionObserver API for IE and old Safari
import LazyLoad from 'vanilla-lazyload' // Lazy load images plugin

import initStickyHeader from './components/initStickyHeader'
import initReviewsSlider from './components/initReviewsSlider'
import initMainNavHoverJs from './components/initDropdowns'
import initDropdownAccordion from './components/initAccordion'

const resizeHandler = new ResizeHandler()

/** Load Events */
window.addEventListener('DOMContentLoaded', () => {
  /**
   * Init lazyload and polyfill
   */
  const lazyLoadInsance = new LazyLoad()
  lazyLoadInsance.update()
  resizeHandler.init()
  initStickyHeader()
  initReviewsSlider()
  initMainNavHoverJs()
  initDropdownAccordion()
})
