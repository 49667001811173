import HoverJs from '../plugins/hoverJs';

export default function initMainNavHoverJs() {
  const options = {
    dropClass: '.menu-item-has-children',
    linkClass: '.nav-link--dropdown',
    activeClass: 'hover-js',
  };

  const holder = document.querySelector('.navbar-nav');
  const hoverJs = new HoverJs(holder, options);

  window.ResponsiveHelper.addRange({
    '1023..': {
      on: () => {
        hoverJs.init();
      },
      off: () => {
        hoverJs.destroy();
      },
    },
  });
};
