import 'slick-carousel';

export default function initReviewsSlider() {
  jQuery('.js-reviews-slider').slick({
    rows: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false,
    dots: true,
    autoplay: true,
    adaptiveHeight: true,
    autoplaySpeed: 8000,
  })
}
