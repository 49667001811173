import '../plugins/accordionPlugin';

export default function initDropdownAccordion () {
  window.ResponsiveHelper.addRange({
    '..1023': {
      on: () => {
        jQuery('.navbar-nav').slideAccordion({
          opener: '.js-accordion-opener',
          slider: '.accordion-dropdown',
          activeClass:'hover-js',
          animSpeed: 300,
					allowClickWhenExpanded: true,
        });
      },
      off: () => {
        jQuery('.navbar-nav').slideAccordion('destroy');
      },
    },
  });
}